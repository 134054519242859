import React, { useState } from 'react';
import axios from 'axios';

const Dominios = () => {
  const [domain, setDomain] = useState('');
  const [status, setStatus] = useState('');

  const checkDomain = async () => {
    try {
      const options = {
        method: 'GET',
        url: 'https://domainr.p.rapidapi.com/v2/status',
        params: { domain },
        headers: {
          'X-RapidAPI-Host': 'domainr.p.rapidapi.com',
          'X-RapidAPI-Key': '4731ffb5a3mshd9716fe5d0149abp1da899jsn192d6b1574f9' // Tu API key aquí
        }
      };

      const response = await axios.request(options);
      if (response.data.status) {
        setStatus(response.data.status[0].summary);
      } else {
        setStatus('No se pudo verificar el dominio.');
      }
    } catch (error) {
      console.error(error);
      setStatus('Error verificando el dominio.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-8">
          Buscar y Comprar un Dominio en Minutos
        </h2>
        <p className="mt-4 text-xl">
          Utiliza nuestra herramienta de verificación de dominios para encontrar el nombre perfecto para tu proyecto en línea.
        </p>
        <div className="mt-8 flex justify-center">
          <input
            type="text"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            placeholder="Introduce un dominio"
            className="border border-gray-300 p-2 rounded-l-md focus:outline-none"
          />
          <button
            onClick={checkDomain}
            className="bg-indigo-600 text-white px-4 py-2 rounded-r-md hover:bg-indigo-700"
          >
            Buscar
          </button>
        </div>
        {status && (
          <div className="mt-4">
            <p className="text-lg text-gray-700">{status}</p>
          </div>
        )}
        <div className="mt-8">
          <p className="text-gray-500">
            La protección de privacidad WHOIS gratuita está incluida con cada registro de dominio elegible.
          </p>
        </div>
        <div className="mt-4">
          <a href="#" className="text-indigo-600 hover:underline">
            ¿Ya compraste un dominio? Transfiérelo
          </a>
        </div>
      </div>
    </div>
  );
};

export default Dominios;