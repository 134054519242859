import React from 'react';
import { FaServer, FaGlobe, FaMobileAlt, FaLaptopCode } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const services = [
  { name: 'Alojamiento Web', icon: <FaServer />, description: 'Servicio de alojamiento web de alta calidad.' },
  { name: 'Dominios', icon: <FaGlobe />, description: 'Registro y gestión de dominios.' },
  { name: 'Desarrollo de Aplicaciones', icon: <FaMobileAlt />, description: 'Desarrollo de aplicaciones móviles.' },
  { name: 'Desarrollo Web', icon: <FaLaptopCode />, description: 'Desarrollo de sitios web profesionales.' }
];

const Card = ({ service }) => {
  const navigate = useNavigate();

  const handleSelect = (serviceName) => {
    if (serviceName === 'Alojamiento Web') {
      navigate('/hosting');
    } else if (serviceName === 'Dominios') {
      navigate('/dominios');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
      <div className="text-4xl text-indigo-600 mb-4">{service.icon}</div>
      <h3 className="text-xl font-bold">{service.name}</h3>
      <p className="mt-2 text-gray-500">{service.description}</p>
      <button
        onClick={() => handleSelect(service.name)}
        className="mt-4 inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
      >
        Seleccionar
      </button>
    </div>
  );
};

const OurServices = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-8">
          ¿Qué tipo de servicio buscas?
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {services.map((service) => (
            <Card key={service.name} service={service} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurServices;
