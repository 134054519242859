// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/commons/Navbar';
import Footer from './components/commons/Footer';
import Home from './components/pages/Home';
import Hosting from './components/pages/Hosting';
import OurServices from './components/pages/OurServices';
import HostingShare from './components/hosting-share/HostingShare';
import HostingShareFeatures from './components/hosting-share/HostingShareFeatures';
import HostingShareFaqs from './components/hosting-share/HostingShareFaqs';
import HostingSharePrecios from './components/hosting-share/HostingSharePrecios';
import Dominios from './components/pages/Dominios';
import HostingCloud from './components/hosting-cloud/HostingCloud';
import HostingCloudCaracteristicas from './components/hosting-cloud/HostingCloudCaracteristicas';
import HostingCloudPrecios from './components/hosting-cloud/HostingCloudPrecios';
import { initGA, logPageView } from './analytics';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    initGA('G-SSYRXW1WFR');
  }, []);

  return (
    <Router>
      <RouteChangeTracker />
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/hosting" element={<Hosting />} />
            <Route path="/services" element={<OurServices />} />
            <Route path="/hosting-share" element={<HostingShare />} />
            <Route path="/hosting-share/features" element={<HostingShareFeatures />} />
            <Route path="/hosting-share/faqs" element={<HostingShareFaqs />} />
            <Route path="/hosting-share/precios" element={<HostingSharePrecios />} />
            <Route path="/dominios" element={<Dominios />} />
            <Route path="/hosting-cloud" element={<HostingCloud />} />
            <Route path="/hosting-cloud/caracteristicas" element={<HostingCloudCaracteristicas />} />
            <Route path="/hosting-cloud/precios" element={<HostingCloudPrecios />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
